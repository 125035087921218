
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import isEnabled from "@/composables/isEnabled.js";

import services from "../../../axios/dbManag";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import ModalEsportaStorico from "@/components/components-fit/persone/ModalEsportaStorico.vue";

export default defineComponent({
  name: "storico-persona",
  components: { ModalEsportaStorico },
  props: ["persona"],
  setup(props) {
    const storicoPartecipazioneConsiglio = ref<any[]>([]);
    const storicoPartecipazioneTecnici = ref<any[]>([]);
    const storicoTesseramento = ref<any[]>([]);
    const storicoAlbo = ref<any[]>([]);
    const errore = ref(false);
    const loaded = ref(false);

    services.dbManag
      .post(`/anagrafiche/persone/storico/list`, {
        id_persona: props.persona.id,
      })
      .then((res) => {
        if (res.data.results[0].storico_partecipazione_consiglio) {
          res.data.results[0].storico_partecipazione_consiglio.forEach(
            (storico) => {
              storicoPartecipazioneConsiglio.value.push(storico);
            }
          );
        }
        if (res.data.results[0].storico_partecipazione_tecnici) {
          res.data.results[0].storico_partecipazione_tecnici.forEach(
            (storico) => {
              storicoPartecipazioneTecnici.value.push(storico);
            }
          );
        }
        if (res.data.results[0].storico_tesseramento) {
          res.data.results[0].storico_tesseramento.forEach((storico) => {
            storicoTesseramento.value.push(storico);
          });
        }
        if (res.data.results[0].storico_albo) {
          res.data.results[0].storico_albo.forEach((storico) => {
            storicoAlbo.value.push(storico);
          });
        }
        loaded.value = true;
      })
      .catch(() => {
        errore.value = true;
        loaded.value = true;
      });
    //funzione gestione anno
    const getArrayLastYear = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.anno === lastYear;
      });
      return array;
    };
    const getArrayOtherYears = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.anno != lastYear;
      });
      return array;
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Storico Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      storicoPartecipazioneConsiglio,
      storicoPartecipazioneTecnici,
      storicoTesseramento,
      storicoAlbo,
      getArrayLastYear,
      getArrayOtherYears,
      loaded,
      errore,
      isEnabled,
      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      esportaLista,
    };
  },
});
